
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { sez, user_gst_type } from "@/core/data/genericData";
import Quill from "quill/dist/quill.js";
import { useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mask } from "vue-the-mask";
import { hide } from "@popperjs/core";
import JwtService from "@/core/services/JwtService";
import ViewGstInfo from "@/components/modals/forms/ViewGstInfo.vue";
export default defineComponent({
  name: "apps-entity-edit",
  directives: { mask },
  props: {
    id: { required: true },
    // data: {
    //     type: Object
    // }
    
  },
  components: {
    //AddTagsModal
   // ViewGstInfo
  },
  // data: function(){
  //   return{
  //     this.formData['business_mobile'] = ''
  //   };
  // },
  methods: {
    
    onChange() {
      if (this.formData['pincode'] == "") {
        this.formData['city'] = "";
        this.formData['state'] = "";
        this.formData['country'] = "";
      }
    },
    onChangeIndividualPin() {
      if (this.formData1.pincode == "") {
        this.formData1.city = "";
        this.formData1.state = "";
        this.formData1.country = "";
      }
    },

    onClearGst() {
      if (
        this.formData['gstin_search'] == "" ||
        this.formData['gstin_search'] !== "15") 
        {
          this.formData['pincode'] = "";
          this.formData['city'] = "";
          this.formData['state'] = "";
          this.formData['country'] = "";
          this.formData['jurisdiction'] = "";
          this.formData['legal_name'] = "";
          this.formData['basic_name'] = "";
          this.formData['business_type_select'] = "";
          this.formData['gst_type'] = "";
          this.formData['gstin'] = "";
          this.formData['address_line_1'] = "";
          this.formData['state_tin'] = "";
          this.formData['sez'] = "";
          (document.getElementById("view_btn") as HTMLInputElement).style.display ="none";
      }
    },
    onClearPan() {
      if (this.formData['pan_search'] == "" || this.formData['pan_search'] !== "10") {
        this.formData['company_pan'] = "";
      }
    },

    onClearPanInd() {
      if (this.formData1.pan_search1 == "" || this.formData1.pan_search1 !== "10") {
        this.formData1.individual_pan = "";
        this.formData1.individual_name_of_person = "";
      }
    },

    onClick() {
      (document.getElementById("id_data2") as HTMLInputElement).style.display =
        "block";
      (document.getElementById("id_data") as HTMLInputElement).style.display =
        "none";
      (document.getElementById("gst_id") as HTMLInputElement).style.display =
        "none";
      (
        document.getElementById("search_by_gst") as HTMLInputElement
      ).style.display = "none";
      (
        document.getElementById("search_by_pan") as HTMLInputElement
      ).style.display = "none";
      (
        document.getElementById("pan_id") as HTMLInputElement
      ).style.display = "none";
      // (
      //   document.getElementById("pansearch1") as HTMLInputElement
      // ).style.display = "block";
      (
        document.getElementById("compan_id") as HTMLInputElement
      ).style.display = "none";
      
      (
        document.getElementById("pan_id1") as HTMLInputElement
      ).style.display = "block";
      (document.getElementById("individual_submit") as HTMLInputElement).style.display =
        "block";
      (document.getElementById("company_submit") as HTMLInputElement).style.display =
        "none";
    },
    onCompanyClick() {
      (document.getElementById("id_data2") as HTMLInputElement).style.display =
        "none";
      (document.getElementById("id_data") as HTMLInputElement).style.display =
        "block";
      (document.getElementById("gst_id") as HTMLInputElement).style.display =
        "block";
      (
        document.getElementById("search_by_gst") as HTMLInputElement
      ).style.display = "block";
      (
        document.getElementById("search_by_pan") as HTMLInputElement
      ).style.display = "block";
      (
        document.getElementById("pan_id") as HTMLInputElement
      ).style.display = "block";
      // (
      //   document.getElementById("pansearch1") as HTMLInputElement
      // ).style.display = "none";
      (
        document.getElementById("compan_id") as HTMLInputElement
      ).style.display = "block";
      
      (
        document.getElementById("pan_id1") as HTMLInputElement
      ).style.display = "none";
      (document.getElementById("individual_submit") as HTMLInputElement).style.display =
        "none";
      (document.getElementById("company_submit") as HTMLInputElement).style.display =
        "block";
    },
  },

  setup(props) {
    const sez_data = sez;
    const user_gst_type_data = user_gst_type;
    const formRef = ref<null | HTMLFormElement>(null);
    const formRefIndividual = ref<null | HTMLFormElement>(null);
    const addNewsModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const loadingHsn = ref<boolean>(false);
    const router = useRouter();
    const shape = ref([]);

    onMounted(async () => {
      //const db_data = {}
      setCurrentPageBreadcrumbs("Business Entity", []);
      await setNobdata();
      await setIndustryTypedata();
      await setBranchTitledata();
      await setBusinessTypedata();
      await setDepartmentdata();
      await setDesignationdata();
      await setROCdata();
    });

    interface ViewProduct {
      product_id;
      trade;
      gstin;
      constitutionOfBusiness;
      gstinStatus;
      taxPayerType;
      registrationDate;
      principal_address;
      other_add;
      directorNames;
      natureOfBusinessActivities;
      complianceRating;
    }

    var resultsView = ref<ViewProduct>();

    resultsView.value = {
      product_id: "",
      trade: "",
      gstin : "",
      constitutionOfBusiness : "",
      gstinStatus : "",
      taxPayerType : "",
      registrationDate : "",
      principal_address : "",
      other_add : "",
      directorNames : "",
      natureOfBusinessActivities: "",
      complianceRating : "",
    };

    const getGstDataInfo = async () => {
      loadingPin.value = true;

      const db_data = { gstin: formData.value['gstin_search'] || formData.value['comp_search'] };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_GSTIN_INFO, db_data)
        .then(({ data }) => {
          console.log(data);
          
          if (data) {
           resultsView.value = {
                product_id: data.gstnDetailed.legalNameOfBusiness,
                trade: data.gstnDetailed.tradeNameOfBusiness,
                constitutionOfBusiness : data.gstnDetailed.constitutionOfBusiness,
                gstinStatus : data.gstnDetailed.gstinStatus,
                taxPayerType : data.gstnDetailed.taxPayerType,
                registrationDate : data.gstnDetailed.registrationDate,
                principal_address :data.gstnDetailed.principalPlaceAddress.address,
                other_add : data.gstnDetailed.principalPlaceAddress.splitAddress.addressLine,
                directorNames : data.gstnDetailed.directorNames.toString(),
                natureOfBusinessActivities : data.gstnDetailed.natureOfBusinessActivities.toString(),
                complianceRating : data.gstnDetailed.complianceRating,
                gstin : data.gstin,
            }
            console.log(resultsView.value)
            loadingPin.value = false;
          }
           else {
            Swal.fire({
              text: "Sorry, It's Invalid GST!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid GST!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

     const getPanData = async () => {
      loadingPin.value = true;

      const db_data = { pan: formData.value['pan_search'] };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_PAN_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data.holder_type == 'company') {
            formData.value['company_pan'] = data["pan"];
            // formData1.value['city'] = data["holder_type"];
            // formData1.value['state'] = data["holder_name"];
            

            loadingPin.value = false;
          } else {
            formData.value['company_pan'] = "";
            

            Swal.fire({
              text: "Sorry, It's Invalid PAN!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            formData.value['pan_search'] = "",
            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid PAN!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

     const getPanData1 = async () => {
      loadingPin.value = true;

      const db_data = { pan: formData1.value['pan_search1'] };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_PAN_SEARCH, db_data)
        .then(({ data }) => {
          console.log("PAN DATA INDIVIDUAL")
          console.log(data);

          if (data.holder_type == 'individual') {
            formData1.value['individual_pan'] = data["pan"];
            // formData1.value['city'] = data["holder_type"];
             formData1.value['individual_name_of_person'] = data["holder_name"];
            

            loadingPin.value = false;
          } else {
            formData1.value['individual_pan'] = "";
            

            Swal.fire({
              text: "Sorry, It's Invalid PAN!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            formData1.value['pan_search1'] = "",
            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid PAN!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

     const formDatatemp = ref({
     valuetbl1: "",
      business_emails: "",
      business_mobile: "",
      mobile_list: ref<any>([]),
      email_list: ref<any>([]),
      landline_list: ref<any>([]),
      business_landline: "",
    
    });


    const formData = ref({});
    //   comp_search : "",
    //   pan_search: "",
    //   gstin_search: "",
    //   basic_name: "",
    //   nob_select: "",
    //   industry_type_select: "",
    //   website: "",
    //   business_type_select: "",
    //   sez: "",
    //   branch_name: "",
    //   branch_title_select: "",
    //   legal_name: "",
    //   business_emails: "",
    //   business_mobile: "",
    //   mobile_list: ref<any>([]),
    //   email_list: ref<any>([]),
    //   landline_list: ref<any>([]),
    //   business_landline: "",
    //   address_line_1: "",
    //   address_line_2: "",
    //   jurisdiction: "",
    //   name_of_person: "",
    //   mobile_select: "",
    //   email_select: "",
    //   landline_select: "",
    //   designation_select: "",
    //   department_select: "",
    //   registrationNo: "",
    //   incorporationateDate: "",
    //   company_pan: "",
    //   cinn: "",
    //   llpinn: "",
    //   dob: "",
    //   anniversary_dob: "",
    //   spouse_dob: "",
    //   pincode: "",
    //   pincode_id: "",
    //   city: "",
    //   state: "",
    //   country: "",
    //   city_id: "",
    //   state_id: "",
    //   country_id: "",
    //   gstin: "",
    //   state_tin: "",
    //   gst_type: "",
    

    const formData1 = ref({
      // individual_pincode : "",
      // individual_city : "",
      // individual_state : "",
      // individual_country : "",
      pan_search1: "",
      individual_spouse_dob: "",
      individual_anniversary_dob: "",
      individual_dob: "",
      individual_mobile_select: "",
      individual_email_select: "",
      individual_landline_select: "",
     // individual_department_select: "",
      individual_address_line_1: "",
      individual_address_line_2: "",
      individual_designation_select: "",
      individual_name_of_person: "",
      individual_pan: "",
      pincode: "",
      pincode_id: "",
      city: "",
      state: "",
      country: "",
      city_id: "",
      state_id: "",
      country_id: "",
    });

    const rules = ref({
      basic_name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      name_of_person: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      mobile_select: [
        {
          required: true,
          message: "Mobile number is required",
          trigger: "change",
        },
      ],
      
      // mobile_list: [
      //   {
      //     required: true,
      //     message: "Please at least one business mobile number",
      //     trigger: "change",
      //   },
      // ],
     
    });

    const rules1 = ref({
       individual_name_of_person: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      individual_designation_select: [
        {
          required: true,
          message: "Profession is required",
          trigger: "change",
        },
      ],
    });

    const loadingPan = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);

    const company_name_search = ref([]);
    const setCompanydata = async (data) => {
      
      loading.value = true;
      try {
        var values = {"search_term" : data}
        //if(values.search_term.length >= 4){
        await store
          .dispatch(Actions.CUST_GET_USER_COMPANY_SEARCH_DP, values)
          .then(({ data }) => {
            company_name_search.value = data;
            console.log("COMPANY NAME")
            console.log(company_name_search.value)
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
            loading.value = false;
          });
        //}
      } catch (e) {
        console.log(e);
        loading.value = false;
      }
    };

     const getGstData1 = async () => {
      loadingPin.value = true;
      const db_data = { gstin:  formData.value['comp_search']};
      console.log("DBDATA")
      console.log(db_data)
      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_GSTIN_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data) {
            formData.value['city'] = data["city_name"];
            formData.value['state'] = data["state_name"];
            formData.value['country'] = "India";
            formData.value['state_tin'] = data["tin"];
            formData.value['city_id'] = data["city_id"];
            formData.value['state_id'] = data["state_id"];
            formData.value['pincode_id'] = data["pincode_id"];
            formData.value['pincode'] = data["pincode"];
            formData.value['country_id'] = data["country_id"];
            formData.value['business_type_select'] = data["business_type_id"];
            formData.value['basic_name'] = data["legal_business_name"];
            formData.value['legal_name'] = data["legal_business_name"];
            formData.value['jurisdiction'] = data["state_jurisdiction"];
            formData.value['address_line_1'] = data["address"];
            formData.value['gst_type'] = data["gst_type"];
            formData.value['gstin'] = data["gstin"];
            formData.value['sez'] = data["sez_yn"];
            formData.value['state_tin'] = data["state_tin"];

            (document.getElementById("view_btn") as HTMLInputElement).style.display ="block";
            
            console.log('formData.value.city')
            console.log(formData.value['city'])
            loadingPin.value = false;
          } else {
            formData.value['city'] = "";
            formData.value['state'] = "";
            formData.value['country'] = "";
            formData.value['state_tin'] = "";
            formData.value['city_id'] = "";
            formData.value['state_id'] = "";
            formData.value['pincode_id'] = "";
            formData.value['country_id'] = "";

            Swal.fire({
              text: "Sorry, It's Invalid GST!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid GST!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

    const getGstData = async () => {
      loadingPin.value = true;

      const db_data = { gstin: formData.value['gstin_search'] };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_GSTIN_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data) {
            formData.value['city'] = data["city_name"];
            formData.value['state'] = data["state_name"];
            formData.value['country'] = "India";
            formData.value['state_tin'] = data["tin"];
            formData.value['city_id'] = data["city_id"];
            formData.value['state_id'] = data["state_id"];
            formData.value['pincode_id'] = data["pincode_id"];
            formData.value['pincode'] = data["pincode"];
            formData.value['country_id'] = data["country_id"];
            formData.value['business_type_select'] = data["business_type_id"];
            formData.value['basic_name'] = data["legal_business_name"];
            formData.value['legal_name'] = data["legal_business_name"];
            formData.value['jurisdiction'] = data["state_jurisdiction"];
            formData.value['address_line_1'] = data["address"];
            formData.value['gst_type'] = data["gst_type"];
            formData.value['gstin'] = data["gstin"];
            formData.value['sez'] = data["sez_yn"];
            formData.value['state_tin'] = data["state_tin"];

          (document.getElementById("view_btn") as HTMLInputElement).style.display ="block";
            loadingPin.value = false;
          } else {
            formData.value['city'] = "";
            formData.value['state'] = "";
            formData.value['country'] = "";
            formData.value['state_tin'] = "";
            formData.value['city_id'] = "";
            formData.value['state_id'] = "";
            formData.value['pincode_id'] = "";
            formData.value['country_id'] = "";

            Swal.fire({
              text: "Sorry, It's Invalid GST!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid GST!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

    const pin = ref([]);
    const getPincodeData = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };

      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin.value = data;

            for (let i = 0; i < data.length; i++) {
              formData.value['city'] = data[i].city_name;
              formData.value['state'] = data[i].state_name;
              formData.value['country'] = data[i].country_name;
              formData.value['city_id'] = data[i].city_id;
              formData.value['state_id'] = data[i].state_id;
              formData.value['pincode_id'] = data[i].pincode_id;
              formData.value['country_id'] = data[i].country_id;

              loadingPin.value = false;
              console.log();
            }
          })

          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
      // else{

      //     formData.value.city = ""
      //     formData.value.state = ""
      //     formData.value.country = ""
      //     formData.value.city_id = ""
      //     formData.value.state_id = ""
      //     formData.value.pincode_id = ""
      //     formData.value.country_id = ""

      //     Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });

      //     loadingPin.value = false;

      //   }
    };

    const ClickPincodeData = async (pin_details) => {
      formData.value['city'] = pin_details.city_name;
      formData.value['state'] = pin_details.state_name;
      formData.value['country'] = pin_details.country_name;
      formData.value['city_id'] = pin_details.city_id;
      formData.value['state_id'] = pin_details.state_id;
      formData.value['pincode_id'] = pin_details.pincode_id;
      formData.value['country_id'] = pin_details.country_id;
    };

    const pin1 = ref([]);
    const getPincodeData1 = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };

      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin1.value = data;

            for (let i = 0; i < data.length; i++) {
              formData1.value.city = data[i].city_name;
              formData1.value.state = data[i].state_name;
              formData1.value.country = data[i].country_name;
              formData1.value.city_id = data[i].city_id;
              formData1.value.state_id = data[i].state_id;
              formData1.value.pincode_id = data[i].pincode_id;
              formData1.value.country_id = data[i].country_id;

              loadingPin.value = false;
              console.log();
            }
          })

          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
      // else{

      //     formData.value.city = ""
      //     formData.value.state = ""
      //     formData.value.country = ""
      //     formData.value.city_id = ""
      //     formData.value.state_id = ""
      //     formData.value.pincode_id = ""
      //     formData.value.country_id = ""

      //     Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });

      //     loadingPin.value = false;

      //   }
    };

    const ClickPincodeData1 = async (pin_details) => {
      formData1.value.city = pin_details.city_name;
      formData1.value.state = pin_details.state_name;
      formData1.value.country = pin_details.country_name;
      formData1.value.city_id = pin_details.city_id;
      formData1.value.state_id = pin_details.state_id;
      formData1.value.pincode_id = pin_details.pincode_id;
      formData1.value.country_id = pin_details.country_id;
    };

    const nob_data = ref([]);
    const setNobdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            nob_data.value = body.business_natures;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const industry_types_data = ref([]);
    const setIndustryTypedata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            industry_types_data.value = body.industry_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const branch_title = ref([]);
    const setBranchTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            branch_title.value = body.branch_title_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const business_type = ref([]);
    const setBusinessTypedata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            business_type.value = body.business_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const department = ref([]);
    const setDepartmentdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            department.value = body.department_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const designation = ref([]);
    const setDesignationdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            designation.value = body.contact_designation_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const roc_list_data = ref([]);
    const setROCdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            roc_list_data.value = body.roc_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const Deletedata = async (record_id, company_id) => {
      const db_data = {
        "record_id"  : record_id,
        "company_id" : company_id
      };
      await store
        .dispatch(Actions.CUST_DELETE_ENTITY_DATA, db_data)
        
    };
    var dt, mo, yy, dtt, defaultdate;
    const roc_value = ref();
    const gstverify = ref();  
    const panverify = ref();  
    const main_id =ref();
    const setBusinessEntityAdd = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      //setFormData(formData.value)
      console.log("MAIN id")
      console.log(data.id)
      main_id.value = data.id;
      console.log("data")
      console.log(data)
      if(data.gstin_search == ""){
      gstverify.value =  0
      }
      if(data.pan_search == ""){
        panverify.value = 0
      }
      if(data.roc_select == ""){
        roc_value.value = 1
      }

      var cinLlpin;
      
      cinLlpin = data.llpinn == "" ? data.cinn : data.llpinn
      if(data.incorporationateDate !== ''){
       dt = ("0" + data.incorporationateDate.getDate()).slice(-2)
       mo = ("0" + (data.incorporationateDate.getMonth() + 1)).slice(-2)
       yy = data.incorporationateDate.getFullYear()
       dtt = (yy+'-'+mo+'-'+dt);
      }
      else{
        defaultdate = '0000-00-00'
      }
      
      const db_data = {
        company_id: 0,
        company_name: data.basic_name,
        company_legal_name: data.legal_name || "",
        company_nob_id: nob_value.value || data.nob_select || "",
        company_industry_type_id: data.industry_type_select || "",
        company_business_type_id: data.business_type_select || "",
        company_roc_id: data.roc_select || roc_value.value || "",
        company_doi: dtt || defaultdate,
        company_cin_llpin: cinLlpin === "" ? 0 : cinLlpin,
        company_registration_number: 1,
        sez_yn: data.sez || "",
        company_pan: data.company_pan || "",
        pan_verified_yn: panverify.value || 0,
        company_website: data.website || "",
        branch_name: data.branch_name || "",
        branch_title_type_id: data.branch_title_select || "",
        business_email_json: data.business_emails || "",
        business_mobile_json: data.business_mobile || "",
        business_landline_json: data.business_landline || "",
        address_line_1: data.address_line_1 || "",
        address_line_2: data.address_line_2 || "",
        pincode_id: Number(data.pincode_id) || 0,
        gst_type: data.gst_type || "",
        gstin: data.gstin || "",
        state_tin: data.state_tin || "",
        jurisdiction: data.jurisdiction || "",
        gstin_verified_yn: gstverify.value || 0,
        contact_name: data.name_of_person,
        designation_id: designation_value.value || data.designation_select || "",
        department_id: data.department_select || "",
        email: data.email_select || "",
        mobile_no: data.mobile_select || "",
        landline_no: data.landline_select || "",
        user_id: user.user_id,
      };

      console.log("MMMMMMMMMMMMMMMMMM")
      console.log(db_data)

      await store
        .dispatch(Actions.CUST_BUSINESS_ENTITY_ADD, db_data)
        .then(({ is_error , message, status, data }) => {
          if (is_error == false){
            Deletedata(main_id.value, data.company_id)
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Company Information created successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({name : "apps-upload-listing"});
              });
            }, 2000);
          }
           if(is_error == true) {
            loading.value = false;
            Swal.fire({
              text: message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    // const setIndividualAdd = async (data) => {
    //   var user = JSON.parse(JwtService.getToken());
    //   const db_data = {
    //     contact_name: data.individual_name_of_person,
    //     designation_id: data.individual_designation_select,
    //     email: data.individual_email_select,
    //     mobile_no: data.individual_mobile_select,
    //     landline_no: data.individual_landline_select,
    //     contact_dob: data.individual_dob,
    //     contact_anniversary_date: data.individual_anniversary_dob,
    //     contact_spouse_dob: data.individual_spouse_dob,
    //     contact_pan: data.individual_pan,
    //     pan_verified_yn: data.pan_search1 !== "" ? 1 || data.pan_search1 : 0,
    //     registration_no: "",
    //     address_line_1: data.individual_address_line_1,
    //     address_line_2: data.individual_address_line_2,
    //     pincode_id: Number(data.pincode_id) || 0,
    //     user_id: user.user_id,
    //   };

    //   await store
    //     .dispatch(Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_ADD, db_data)
    //     .then(({ data }) => {
    //       if (data.is_success) {
    //         // UploadImage(data.category_id)
    //         setTimeout(() => {
    //           loading.value = false;
    //           Swal.fire({
    //             text: "Individual Information created successfully.",
    //             icon: "success",
    //             buttonsStyling: false,
    //             confirmButtonText: "Ok, got it!",
    //             customClass: {
    //               confirmButton: "btn btn-primary",
    //             },
    //           }).then(() => {
    //             router.push({name : "apps-profile-listing"});
    //           });
    //         }, 2000);
    //       } else {
    //         loading.value = false;

    //         Swal.fire({
    //           text: "Sorry, looks like there are some errors detected, please try again.",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });
    //         return false;
    //       }
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          rules
          await setBusinessEntityAdd(formData.value);
          //await setBusinessEntityAdd(formDatatemp.value);
          console.log("aaaaaaaaaaaaaaaaaaaa")
          console.log(formData.value)
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const onMobileEnter = () => {
      debugger;
      if (
        /^[789]\d{9}$/.test(formData.value["business_mobile"]) &&
        !formData.value["mobile_list"].includes(
          formData.value["business_mobile"]
        )
      ) {
        formData.value["mobile_list"].push(formData.value["business_mobile"]);
        formData.value["business_mobile"] = "";
      }
    };

    const onLandLineEnter = () => {
      debugger;
      if (
        /\d{5}([- ]*)\d{6}/.test(formDatatemp.value["business_landline"]) &&
        !formDatatemp.value["landline_list"].includes(
          formDatatemp.value["business_landline"]
        )
      ) {
        formDatatemp.value["landline_list"].push(
          formDatatemp.value["business_landline"]
        );
        formDatatemp.value["business_landline"] = "";
      }
    };

    const onEmailEnter = () => {
      debugger;
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          formDatatemp.value["business_emails"]
        ) &&
        !formDatatemp.value["email_list"].includes(
          formDatatemp.value["business_emails"]
        )
      ) {
        formDatatemp.value["email_list"].push(formDatatemp.value["business_emails"]);
        formDatatemp.value["business_emails"] = "";
      }
    };

    // const submitIndividual = async () => {
    //   loading.value = true;

    //   if (!formRefIndividual.value) {
    //     loading.value = false;
    //     return;
    //   }

    //   formRefIndividual.value.validate(async (valid) => {
    //     if (valid) {
    //       await setIndividualAdd(formData1.value);
    //       rules1
    //     } else {
    //       loading.value = false;
    //       return false;
    //     }
    //   });
    // };

    const sampledata = async () => {
      
      loading.value = true;
      
            console.log((dropdown_data.value == 'undefined'))
            
      try {
        var values = {"search_term" : formData.value['comp_search']}
        //if(values.search_term.length >= 4){
        await store
          .dispatch(Actions.CUST_GET_USER_COMPANY_SEARCH_DP, values)
          .then(({ data }) => {
            dropdown_data.value = data[0].gstin;
            console.log(dropdown_data.value)
            getGst1()
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
              formData.value['comp_search'] = ''
              console.log(formData.value['comp_search'])
          });
        //}
      } catch (e) {
        console.log(e);
      }
    };

    const getGst1 = async () => {
              loadingPin.value = true;
              const db_data = { gstin: dropdown_data.value };
              console.log("DBDATA")
              console.log(db_data)
              await store
                .dispatch(Actions.CUST_GET_USER_PROFILE_GSTIN_SEARCH, db_data)
                .then(({ data }) => {
                  console.log(data);

                  if (data) {
                    formData.value['city'] = data["city_name"];
                    formData.value['state'] = data["state_name"];
                    formData.value['country'] = "India";
                    formData.value['state_tin'] = data["tin"];
                    formData.value['city_id'] = data["city_id"];
                    formData.value['state_id'] = data["state_id"];
                    formData.value['pincode_id'] = data["pincode_id"];
                    formData.value['pincode'] = data["pincode"];
                    formData.value['country_id'] = data["country_id"];
                    formData.value['business_type_select'] = data["business_type_id"];
                    formData.value['basic_name'] = data["legal_business_name"];
                    formData.value['legal_name'] = data["legal_business_name"];
                    formData.value['jurisdiction'] = data["state_jurisdiction"];
                    formData.value['address_line_1'] = data["address"];
                    formData.value['gst_type'] = data["gst_type"];
                    formData.value['gstin'] = data["gstin"];
                    formData.value['sez'] = data["sez_yn"];
                    formData.value['state_tin'] = data["state_tin"];

                    (document.getElementById("view_btn") as HTMLInputElement).style.display ="block";
                    
                    console.log('formData.value.city')
                    console.log(formData.value['city'])
                    loadingPin.value = false;
                  } else {
                    formData.value['city'] = "";
                    formData.value['state'] = "";
                    formData.value['country'] = "";
                    formData.value['state_tin'] = "";
                    formData.value['city_id'] = "";
                    formData.value['state_id'] = "";
                    formData.value['pincode_id'] = "";
                    formData.value['country_id'] = "";

                    Swal.fire({
                      text: "Sorry, It's Invalid GST!",
                      icon: "error",
                      buttonsStyling: false,
                      confirmButtonText: "Ok, got it!",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                    });

                    loadingPin.value = false;
                  }
                })
                .catch(({ response }) => {
                  Swal.fire({
                    text: "Sorry, It's Invalid GST!",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });

                  loadingPin.value = false;
                });
            };

    //var formDataview = ref({})
    const nob_value = ref()
    const industry_value = ref()
    const dropdown_data = ref();
    const company_valid = ref()
    const designation_value = ref()
     const setFormData = async (id) => {    
        
        try {

          var values = { "record_id"  : id}
            
          await store.dispatch(Actions.CUST_VIEW_ENTITY_DATA, values).then(({ data }) => {
             //company_valid.value = data.company_nob_name
             //dropdown_data = document.getElementById('nature_id')
            // console.log(dropdown_data)
            // if( company_valid.value == dropdown_data.options[dropdown_data.selectedIndex].value ){
            //    console.log("VALID")

            //  }
        //     for (let i = 0; i < dropdown_data.length; ++i) {
        //     if (dropdown_data.options[i].value == company_valid.value) {
                
        //         console.log("VALID")
        //         dropdown_data = company_valid.value;

        //     }
        // }
        
      

        setCompanydata(data.company_name)
            nob_value.value = data.company_nob_id
            industry_value.value = data.company_industry_type_id
            designation_value.value = data.designation_id
            formData.value = {
                id: data.record_id,
                basic_name : data.company_name,
                comp_search : data.company_name,
                name_of_person : data.contact_person_name,
                incorporationateDate : "",
                // contact_person_designation : data.contact_person_designation,
                mobile_select : data.contact_person_mobile,
                email_select : data.contact_person_email,
                city : data.company_city,
                state : data.company_state,
                pincode : data.company_pincode,
                website : data.company_website,
                nob_select : data.company_nob_name,
                designation_select : data.contact_person_designation,
                cinn  :  (data.company_business_type_id != 6 ? data.company_cin_llpin : "") || "",
                llpinn  :  (data.company_business_type_id == 6 ? data.company_cin_llpin : "") || "",
            };
            console.log("FORM DATAqqqqqqqqqqqqqqqqqq")
            console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    onMounted( async () => {   
      setCurrentPageBreadcrumbs("Entity Data", []);
      await setFormData(props.id);
      await setCompanydata(company_name_search.value)
      console.log("qqqqqqqqqqqqqqqqqqqqqqqq")
      //await getGstData1()
      await sampledata()
      
      });  


    return {
      formData,
      formData1,
      formDatatemp,
      //submitIndividual,
      rules,
      rules1,
      submit,
      formRef,
      formRefIndividual,
      loading,
      nob_data,
      setNobdata,
      industry_types_data,
      setIndustryTypedata,
      business_type,
      setBusinessTypedata,
      branch_title,
      setBranchTitledata,
      department,
      setDepartmentdata,
      designation,
      setDesignationdata,
      roc_list_data,
      setROCdata,
      addNewsModalRef,
      sez_data,
      user_gst_type_data,
      ClickPincodeData,
      ClickPincodeData1,
      getPincodeData,
      getPincodeData1,
      pin,
      pin1,
      loadingPin,
      loadingPan,
      getGstData,
      getGstData1,
      onMobileEnter,
      onEmailEnter,
      onLandLineEnter,
      getPanData,
      getPanData1,
      setCompanydata,
      company_name_search,
      getGstDataInfo,
      resultsView,
      sampledata,
      getGst1,
    };
  },
});
